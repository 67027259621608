<template>
  <HomeLayout>
    <template #content>
      <div class="flex flex-col px-8 py-4 green-border bg-space-dark-blue space-y-4 h-full">
        <div class="flex flex-col items-center mb-10">
          <h1 class="geminis text-space-green text-4xl">
            {{ $t(`bundles.form.${bundle && bundle.id ? 'title_edit' : 'title'}`) }}
          </h1>
          <p class="max-w-screen-sm	text-center">
            {{ $t(`bundles.form.subtitle`) }}
          </p>
        </div>
        <div class="flex flex-col md:flex-row md:space-x-24 justify-between">
          <div class="flex flex-col space-y-16 md:w-1/2">
            <TextFormInput
              v-model="bundle.name"
              :label="$t('bundles.name')"
              label-classname="!text-space-green text-2xl font-raleway-extra-bold mb-4"
              wrapper-classname="!bg-white"
              input-classname="!text-space-dark-blue"
              :errors="errors"
              field="name"
            />
            <div class="flex flex-col">
              <div class="flex items-center space-x-6 mb-2">
                <span class="text-2xl text-space-green font-raleway-extra-bold">{{
                  $t('bundles.form.access.label')
                }}</span>
                <ul class="list-none flex space-x-4">
                  <li
                    v-for="(visibility, index) in visibilities"
                    :key="index"
                  >
                    <CheckboxField
                      :label="$t('common.access.' + visibility).toString()"
                      :on-change="() => bundle.access = visibility"
                      :value="bundle.access && bundle.access === visibility"
                      checkbox-class="!border-white bg-white"
                      label-class="text-white"
                    />
                  </li>
                </ul>
              </div>
              <HelpInfo
                :help="$t('bundles.form.access.help')"
                text-classname="text-gray-200"
                icon-classname="!text-gray-200"
              />
            </div>
          </div>
          <div class="flex flex-col w-full md:w-1/2">
            <Table
              :title="$t('bundles.exercises')"
              :headers="headers"
              table-container-classname="rounded-xl mt-3 bg-white mx-0"
              :data="bundle.exercises"
              :on-select-all="onSelectAll"
              :selected-rows="selectedRows"
              :actions="tableActions"
            >
              <template #body>
                <ExercisesTableBody
                  :exercises="bundle.exercises"
                  :user="user"
                  :on-row-selected="onRowSelected"
                  :selected-rows="selectedRows"
                  :actions="actions"
                />
              </template>
            </Table>
            <span v-if="errors.exercises" class="text-red-500">{{ errors.exercises }}</span>
          </div>
        </div>
        <PrimaryButton
          class="self-end justify-self-end bg-white h-10 !py-0"
          classes="!text-space-dark-blue"
          :text="$t('bundles.form.submit')"
          @click="handleSubmit"
        />
      </div>
      <Modal
        v-if="showModal"
        :title="$t('bundles.form.add_exercises')"
        :value="showModal"
        :on-close="() => showModal = false"
        content-class="modal-content relative justify-center bg-transparent max-w-screen-xl overflow-y-auto min-h-full !justify-start"
        classes="my-12"
      >
        <template #content>
          <BundlesExerciseModal
            :bundle="bundle"
            @add-exercises="handleAddExercises"
          />
        </template>
      </Modal>
      <Modal
        v-if="!!exerciseToShow"
        :on-close="() => exerciseToShow = null"
        :value="!!exerciseToShow"
      >
        <template #content>
          <ExerciseDetailsModal
            :exercise="exerciseToShow"
          />
        </template>
      </Modal>
    </template>
  </HomeLayout>
</template>
<script>
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import {GAME_VISIBILITY_LIST} from "@/constants/games/GameVisibilityConstants";
import CheckboxField from "@/components/checkbox/CheckboxField.vue";
import ExercisesTableBody from "@/components/exercises/ExercisesTableBody.vue";
import {EXERCISES_TABLE_HEADER} from "@/constants/exercices/ExercicesConstants";
import {mapGetters} from "vuex";
import Table from "@/components/Table/Table.vue";
import HomeLayout from "@/layouts/HomeLayout.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import Modal from "@/components/modals/DefaultModal.vue";
import BundlesExerciseModal from "@/components/modals/bundles/BundleExercicesModal.vue";
import ExerciseDetailsModal from "@/components/modals/exerciseDetailsModal.vue";
import HelpInfo from "@/components/help/HelpInfo.vue";
import BundleService from "@/services/bundles/BundleService";
import FormService from "@/services/formService";
import i18n from "@/i18n/i18n";
import {ROUTE_MY_EXERCISES_BUNDLES} from "@/router";
import {TOAST_DEFAULT} from "@/constants/toast/toastConstants";

export default {
  name: 'BundleConfiguration',
  components: {
    HelpInfo,
    ExerciseDetailsModal,
    BundlesExerciseModal,
    Modal,
    PrimaryButton,
    HomeLayout,
    Table,
    ExercisesTableBody,
    CheckboxField,
    TextFormInput
  },
  data() {
    return {
      errors: {},
      selectedRows: [],
      exerciseToShow: null,
      bundle: {
        access: 'private'
      },
      visibilities: GAME_VISIBILITY_LIST,
      headers: EXERCISES_TABLE_HEADER(true),
      showModal: false,
      actions: {
        view: {
          label: i18n.t('exercises.actions.view'),
          method: this.handleViewDetails
        },
        remove: {
          label: i18n.t('exercises.actions.delete'),
          method: this.handleDelete,
        }
      }
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getBundle(this.$route.params.id);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      await this.getBundle(to.params.id);
    }
    next();
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    tableActions() {
      return {
        add: {
          method: () => this.showModal = true,
          label: i18n.t('exercises.actions.add')
        },
        remove: {
          method: this.selectedRows && this.selectedRows.length && this.handleDeleteBulk,
          label: i18n.t('exercises.actions.delete_many')
        }
      };
    }
  },
  methods: {
    getBundle(id){
      BundleService.getBundle(id)
        .then((bundle) => {
          this.bundle = bundle;
        });
    },
    handleViewDetails(exercise) {
      this.exerciseToShow = exercise;
    },
    handleAddExercises(exercises) {
      this.bundle = {
        ...this.bundle,
        exercises: [...this.bundle.exercises ?? [], ...exercises],
      };
      this.showModal = false;
    },
    onRowSelected(exercise) {
      this.selectedRows = this.selectedRows.includes(exercise.id)
        ? this.selectedRows.filter(index => index !== exercise.id)
        : [...this.selectedRows, exercise.id];
    },
    handleDelete(exercise) {
      const deletedIndex = this.bundle.exercises.findIndex(bundleExercise => bundleExercise.id === exercise.id);
      this.selectedRows = this.selectedRows.filter((index) => index !== deletedIndex);

      this.bundle = {
        ...this.bundle,
        exercises: this.bundle.exercises.filter((existingExercise) => existingExercise.id !== exercise.id),
      };
    },
    onSelectAll: function () {
      this.selectedRows = this.selectedRows.length === this.bundle.exercises.length
        ? []
        : this.bundle.exercises.map((exercise) => exercise.id);
    },
    handleDeleteBulk() {
      this.bundle = {
        ...this.bundle,
        exercises: this.bundle.exercises.filter((exercise) => !this.selectedRows.includes(exercise.id)),
      };
      this.selectedRows = [];
    },
    handleSubmit() {
      const errors = BundleService.validateBundleForm(this.bundle);
      this.errors = errors;
      if (FormService.hasErrors(errors)) {
        return;
      }

      const serviceFunction = this.bundle.id ? BundleService.updateBundle : BundleService.createBundle;

      serviceFunction(this.bundle)
        .then(() => {
          this.$toast.success(this.$t('bundles.form.success'), TOAST_DEFAULT);
          this.$router.push(ROUTE_MY_EXERCISES_BUNDLES);
        });
    }
  }
};
</script>
<style scoped>
>>> .table-container {
  @apply mx-0;
  max-height: calc(70vh - 150px);
}

>>> .actions {
  @apply !w-24;
}

>>> .modal-content {
  max-height: 98vh;
}
</style>
