<template>
  <div class="wrapper-dropdown flex flex-wrap relative" ref="Wrapper">
    <div class="w-full">
      <div class="inline-flex align-middle w-full">
        <button
          ref="btnDropdownRef"
          type="button"
          @click="toggleDropdown()"
        >
          <slot name="button" />
        </button>
        <div
          ref="popoverDropdownRef"
          :class="dropdownPopoverShow ? '' : 'hidden'"
          class="dropdown-menu bg-space-green text-base z-50 float-left py-2 list-none text-left text-black rounded shadow-lg mt-4 w-full"
        >
          <slot name="items" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {computePosition} from "@floating-ui/vue";

export default {
  name: 'DropdownMenu',
  emits: ['close'],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    popoverClass: {
      type: String
    }
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  watch: {
    hideMenu() {
      if (this.hideMenu){
        this.dropdownPopoverShow = false;
      }
    }
  },
  mounted() {
    this.closeMenuListenerOnOutsideClick();

  },
  updated() {
    computePosition(this.$refs.Wrapper, this.$refs.popoverDropdownRef, {
    })
      .then(({x, y}) => {
        Object.assign(this.$refs.popoverDropdownRef.style, {top: `${y}px`, left: `${x}px`});
      });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMenuListenerOnOutsideClick);
  },
  methods: {
    toggleDropdown: function () {
      if (this.hideMenu){
        return;
      }
      this.dropdownPopoverShow = !this.dropdownPopoverShow;
    },
    closeMenuListenerOnOutsideClick() {
      document.addEventListener('click', (e) => {
        if (this.dropdownPopoverShow && this.$refs.btnDropdownRef && !this.$refs.btnDropdownRef.contains(e.target) && !this.$refs.popoverDropdownRef.contains(e.target)) {
          this.dropdownPopoverShow = false;
          this.$emit('close');
        }
      });
    }
  }
};
</script>
<style scoped>

.wrapper-dropdown{
  user-select: none;
}

.dropdown-menu{
  @apply absolute;
  min-width: min-content;
}

.dropdown-menu > *:hover {
  @apply bg-white cursor-pointer;
}
</style>
