<template>
  <DropdownMenu
    :key="filter.name"
    popover-class="bg-white"
    @close="opened = false"
  >
    <template #button>
      <button
        class="text-space-dark-blue border border-space-dark-blue px-4 py-1 rounded-md flex items-center space-x-2 font-semibold"
        @click="opened = !opened">
        <span>{{ filter.name }}</span>
        <font-awesome-icon
          :icon="`fa-solid fa-caret-${opened ? 'up' : 'down'}`"
          class="text-space-dark-blue"
        />
      </button>
    </template>
    <template class="bg-white w-full items font-semibold space-y-4">
      <FilterCheckbox
        v-for="option in filter.options"
        :option="option"
        :key="option.value"
        @click="() => handleSelect(option)"
        :value="test.value && test.value.includes(option.value)"
      />
    </template>
  </DropdownMenu>
</template>
<script>
import DropdownMenu from "@/components/dropdowns/DropdownMenu.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import FilterCheckbox from "@/components/filters/FilterCheckbox.vue";

export default {
  name: 'SelectFilter',
  emits: ['change'],
  components: {FilterCheckbox, DropdownMenu, FontAwesomeIcon},
  data() {
    return {
      opened: false
    };
  },
  props: {
    filter: {
      type: Object,
      required: true,
      default: () => ({})
    },
    //prop value type object or array
    test: {
      required: true,
      default: () => ({})
    },
  },
  methods: {
    handleSelect(option) {
      this.$emit('change', option.value);
    }
  }
};
</script>
<style scoped>
.items {
  max-height: 150px;
  overflow-y: auto;
}

>>> .dropdown-menu {
  @apply bg-white border !border-space-dark-blue rounded-md;
}

</style>
