<template>
  <div class="mx-2">
    <div
      class="flex flex-col" v-if="!!exerciseToShow">
      <div class="sticky mb-2 top-0 -ml-1 bg-white">
        <GoBackButton
          text-classname="!text-space-dark-blue"
          class="sticky bg-white top-0 !text-space-dark-blue"
          @click="() => exerciseToShow = null"/>
      </div>
      <ExerciseDetailsModal :exercise="exerciseToShow"/>
    </div>
    <div
      v-if="!exerciseToShow"
      class="bundle-exercises-container bg-white flex flex-col py-4 px-8 space-y-4 !w-full">
      <Filters
        :value="filters"
        :available-filters="availableFilters"
        @change="filters = $event"
      />
      <SearchBar
        v-if="false == true"
        v-model="search"
        container-classname="h-10 mt-2 self-center w-2/3"
        input-wrapper-classname="h-10"
        :placeholder="$t('exercises.search_placeholder')"
      />

      <Table
        :headers="headers"
        table-container-classname="rounded-xl mt-3 bg-white"
        :data="exercises"
        :selected-rows="selectedRows"
        :is-loading="isLoading"
        :on-select-all="handleSelectAll"
        :pagination-values="paginationValues"
        @scroll-end="handleScrollEnd"
      >
        <template #body>
          <ExercisesTableBody
            v-if="!isLoading"
            :exercises="exercises"
            :on-row-selected="onRowSelected"
            :selected-rows="selectedRows"
            :actions="actions"
          />
        </template>
      </Table>
      <PrimaryButton
        :text="$t('common.add')"
        :bg-color="selectedRows.length > 0 ? 'bg-space-green' : 'space-gray'"
        class="self-center mt-4"
        :disabled="selectedRows.length === 0"
        @click="handleAddExercises"
      />
    </div>
  </div>
</template>
<script>
import {EXERCISES_FILTERS, EXERCISES_TABLE_HEADER} from "@/constants/exercices/ExercicesConstants";
import SearchBar from "@/components/inputs/SearchBar.vue";
import Table from "@/components/Table/Table.vue";
import ExercisesTableBody from "@/components/exercises/ExercisesTableBody.vue";
import ExerciseDetailsModal from "@/components/modals/exerciseDetailsModal.vue";
import GoBackButton from "@/components/buttons/GoBackButton.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import Filters from "@/components/filters/Filters.vue";
import i18n from "@/i18n/i18n";
import ExercisesService from "@/services/ExercisesService";
import {createQueryParamString} from "@/utils/requests/FilterQueryParamGenerator";

export default {
  name: 'BundlesExerciseModal',
  emits: ['add-exercises'],
  components: {
    Filters,
    PrimaryButton,
    GoBackButton, ExerciseDetailsModal, ExercisesTableBody, Table, SearchBar},
  props: {
    bundle: {
      type: Object
    },
  },
  data() {
    return {
      headers: EXERCISES_TABLE_HEADER(true),
      filters: [],
      selectedRows: [],
      isLoading: true,
      search: '',
      availableFilters: EXERCISES_FILTERS,
      exerciseToShow: null,
      exercises: [],
      paginationValues: {
        page: 1,
        pageSize: 10,
      },
      actions: {
        view: {
          method: this.handleViewDetails,
          label: i18n.t('exercises.actions.view'),
        }
      }
    };
  },
  watch: {
    search: {
      handler: function () {
        this.paginationValues = {
          pageSize: 10,
          page: 1,
        };
        this.searchExercises();
      }
    },
    filters: {
      handler: function () {
        this.paginationValues = {
          pageSize: 10,
          page: 1,
        };
        this.searchExercises();
      }
    }
  },
  mounted() {
    this.searchExercises();
  },
  methods: {
    handleScrollEnd() {
      if (this.paginationValues.page < this.paginationValues.totalPages) {
        this.searchExercises(this.paginationValues.page + 1);
      }
    },
    onRowSelected(exercise) {
      if (this.selectedRows.includes(exercise.id)) {
        this.selectedRows = this.selectedRows.filter((id) => id !== exercise.id);
      } else {
        this.selectedRows = [...this.selectedRows, exercise.id];
      }
    },
    searchExercises(page) {
      if (!page){
        this.selectedRows = [];
        this.exercises = [];
        this.isLoading = true;
      }

      ExercisesService.search({
        ...this.paginationValues,
        page: page || this.paginationValues.page,
        filters: createQueryParamString([
          ...this.filters,
          {
            field: 'exercise.id',
            operator: 'notIn',
            value: this.bundle.exercises ? this.bundle.exercises.map(exercise => exercise.id) : []
          }
        ])
      })
        .then((searchResponse) => {
          this.exercises = [...(page ? [...this.exercises] : []), ...searchResponse.results];
          this.paginationValues = {
            ...this.paginationValues,
            total: searchResponse.total,
            page: searchResponse.page,
            totalPages: searchResponse.totalPages,
            totalItemsInPage: searchResponse.totalItemsInPage
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSelectAll() {
      if (this.selectedRows.length === this.exercises.length) {
        this.$set(this, 'selectedRows', []);
        this.selectedRows = [];
      } else {
        this.$set(this, 'selectedRows', this.exercises.map((exercise) => exercise.id));
      }
    },
    handleViewDetails(exercise) {
      this.exerciseToShow = exercise;
    },
    handleAddExercises() {
      this.$emit('add-exercises', this.exercises.filter((exercise) => this.selectedRows.includes(exercise.id)));
    }
  }
};
</script>
<style scoped>
.bundle-exercises-container {
  min-height: 50vh;
}

>>> .table-container {
  max-height: 40%;
}
</style>
