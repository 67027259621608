<template>
  <div class="flex flex-col space-y-3">
    <div
      v-if="value.length"
      class="flex flex-wrap space-x-2 items-center">
      <template v-for="filter in value">
        <Chip
          v-for="(value, index) in Array.isArray(filter.value) ? filter.value : [filter.value]"
          :key="`${filter.field}-${index}`"
          :label="availableFilters.find(availableFilter => availableFilter.field === filter.field).options.find(option => option.value === value).name"
          @click="() => handleFilterChange(filter, value)"
        />
      </template>

      <span
        v-if="Object.values(this.value).flat().find(filter => filter.value && filter.value.length > 0)"
        class="font-bold text-space-dark-blue cursor-pointer underline"
        @click="$emit('change', [])">{{ $t('common.reset') }}</span>
    </div>
    <div class="flex flex-wrap space-x-2">
      <SelectFilter
        v-for="filter in availableFilters"
        :key="filter.field"
        :filter="filter"
        :test="value.find(f => f.field === filter.field)"
        @change="(newValue) => handleFilterChange(filter, newValue)"
      />
    </div>
  </div>
</template>
<script>
import Chip from "@/components/chips/Chip.vue";
import SelectFilter from "@/components/filters/SelectFilter.vue";

export default {
  name: 'Filters',
  emits: ['change'],
  components: {SelectFilter, Chip},
  props: {
    availableFilters: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    handleFilterChange(filter, value) {
      let newFilters = [...this.value.filter(existingFilter => existingFilter.field !== filter.field),];
      const existingFilter = this.value.find(existingFilter => existingFilter?.field === filter.field);

      if (!!existingFilter && (Array.isArray(existingFilter.value) ? existingFilter.value.includes(value) : existingFilter.value === value)) {
        newFilters = [
          ...newFilters,
          {
            field: filter.field,
            operator: filter.operator,
            value: Array.isArray(existingFilter.value) ? existingFilter.value.filter((item) => item !== value) : undefined
          }
        ];
      } else {
        newFilters = [
          ...newFilters,
          {
            field: filter.field,
            operator: filter.operator,
            value: this.availableFilters.find(availableFilter => availableFilter.field === filter.field)?.isArray ? [...(existingFilter?.value ? [...existingFilter.value] : []), value] : value
          }
        ];
      }
      this.$emit('change', [...newFilters]);
    },
  }
};
</script>
